import { AxiosResponse } from "axios";
import { config } from "../config/configurations";
import { instance } from "./baseApi";

export function generateFromPrompt(prompt: string): Promise<AxiosResponse> {
	return instance.post(`/${config.REACT_APP_API_VERSION}/assistant/predict`, {
		prompt: prompt,
	});
}
export function initFlow(text: string): Promise<AxiosResponse> {
	return instance.post(`/v1/rfps/init`, {
		text,
	});
}

export function chat(prompt: string, conversationHistory: []): Promise<AxiosResponse> {
	return instance.post(`/v1/aitour/local/search`, {
		text: prompt,
		conversation_history: conversationHistory,
	});
}

export function translate(text: string): Promise<AxiosResponse> {
	return instance.post(`/${config.REACT_APP_API_VERSION}/assistant/translate`, {
		sentence: text,
	});
}

export function searchUsers(text: string): Promise<AxiosResponse> {
	return instance.post(`/${config.REACT_APP_API_VERSION}/assistant/users/search`, {
		user: text,
	});
}

export function getRFPInfos(form: any): Promise<AxiosResponse> {
	return instance.post(`/${config.REACT_APP_API_VERSION}/assistant/process/rfp`, form);
}

export function getGraphRag(): Promise<AxiosResponse> {
	return instance.get(`/v1/aitour/graph`);
}
