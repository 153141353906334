import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
	BottomNavigation,
	BottomNavigationAction,
	Box,
	CircularProgress,
	Paper,
	Typography,
} from "@mui/material";
import { TopBarHeader } from "../../components/header";

import { Homepage as ExcelHomepage } from "./excelHP";

import { OfficeTabProps } from "../../interfaces/IOfficeTab";

import { tabList } from "./homepageTabs";

interface ITab {
	id: string;
	title: string;
	icon: any;
	subtitle: string;
}

export const Homepage: React.FC<OfficeTabProps> = (props: OfficeTabProps) => {
	const [msProduct, setMsProduct] = useState<string>("");
	const [subtitle, setSubtitle] = useState<string>("Summary of your document");
	const [tabs, setTabs] = useState<ITab[]>([]);

	var [isOfficeDocument, setIsOfficeDocument] = useState<boolean>(false);
	var [isLoading, setIsLoading] = useState<boolean>(false);
	var [selectedPage, setSelectedPage] = useState<string>("assistant");

	const { tab } = useParams<{ tab?: string }>();

	var changeTab = (reach: string) => {
		tabs.forEach((tab: any) => {
			if (tab.id === reach) {
				setSubtitle(tab.subtitle);
			}
		});
		setSelectedPage(reach);
	};

	useEffect(() => {
		Office.onReady((info: any) => {
			console.log("Office.onReady", info.host);
			setMsProduct(info.host as string);
		});
	}, []);

	useEffect(() => {
		if (msProduct === null) {
			return;
		}
		setTabs(tabList[msProduct]);
	}, [msProduct]);

	useEffect(() => {
		Office.onReady((info: any) => {
			setIsOfficeDocument(
				info.host === Office.HostType.Word ||
					info.host === Office.HostType.Excel ||
					info.host === Office.HostType.PowerPoint
			);
		});
	}, []);

	useEffect(() => {
		if (tab) {
			changeTab(tab);
		}
	}, [tab]);

	return (
		<Box
			sx={{
				height: "100vh",
				display: "flex",
				width: "100%",
				flexDirection: "column",
				position: "relative",
				overflow: "hidden",
			}}
		>
			<TopBarHeader title="Homepage" subtitle={subtitle} mode="rfp" />
			{msProduct !== null && (
				<>
					{isOfficeDocument && isLoading && (
						<Box
							sx={{
								width: "100%",
								height: "calc(100% - 50px)",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: "white",
							}}
						>
							<Box
								sx={{
									width: "90%",
									height: "100%",
									backgroundColor: "rgba(255,255,255,0.5)",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									zIndex: 999,
								}}
							>
								<Typography
									sx={{
										margin: "auto",
									}}
								>
									We are processing your document. Please do not close this panel.
								</Typography>
								<CircularProgress
									sx={{
										margin: "auto",
									}}
								/>
							</Box>
						</Box>
					)}
					<Box
						sx={{
							width: "100%",
							border: "none",
							contain: "content",
							height: "calc(100% - 50px)",
							backgroundColor: "white",
						}}
					>
						{isOfficeDocument && !isLoading && (
							<>
								{msProduct === "Excel" && (
									<ExcelHomepage
										selectedPage={selectedPage}
										setIsLoading={setIsLoading}
										{...props}
									/>
								)}
							</>
						)}
					</Box>
					<Paper
						sx={{ position: "fixed", bottom: 0, left: 0, right: 0, maxWidth: "100%" }}
						elevation={3}
					>
						<BottomNavigation
							className="bottom-navigation-menu"
							showLabels
							sx={{ zIndex: "999999", width: "100%" }}
						>
							{tabs &&
								tabs.map((tab: any) => {
									return (
										<BottomNavigationAction
											key={tab.id}
											label={tab.title}
											aria-selected={selectedPage === tab.id}
											disabled={isLoading || !isOfficeDocument}
											icon={tab.icon}
											sx={{
												":hover": {
													color: "primary.main",
												},
												"&[aria-selected='true']": {
													color: "primary.main",
												},
												":disabled": {
													color: "#d6d6d6",
												},
											}}
											onClick={() => changeTab(tab.id)}
										/>
									);
								})}
						</BottomNavigation>
					</Paper>
				</>
			)}
			{msProduct === null && (
				<Box
					sx={{
						width: "100%",
						height: "calc(100% - 58px)",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "white",
					}}
				>
					<Typography
						sx={{
							margin: "auto",
						}}
					>
						Please open an MS Office document to start
					</Typography>
				</Box>
			)}
		</Box>
	);
	// {msProduct === "PowerPoint" && <PowerPointHomepage {...props} />}
};
